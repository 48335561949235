import DefaultLayout from "../layouts/default.layout";
import AccountInformationCard from "../components/cards/accountInformation.card";
import LoggedIn from "../layouts/loggedIn.layout";

const AccountInformation = () => {
  return (
    <LoggedIn>
      <DefaultLayout title="Account Information">
        <AccountInformationCard />
      </DefaultLayout>
    </LoggedIn>
  );
};

export default AccountInformation;
