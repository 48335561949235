import { useNavigate } from "react-router-dom";
import useLogin from "../../hooks/useLogin";
import {useState} from "react";

const LoginForm = () => {
  const {loginError, setLoginError} = new useState('');
    const showError = () => {
        console.log(loginError);
        if(loginError !== ""){
            return <div className={'.error-message'}>{loginError}</div>
        }
    }
  const { loginForm, setLoginForm, handleLogin } = useLogin();

  const handleChange = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };
  const navigate = useNavigate();
  const navigateToReset = () => {
    navigate("/reset-password");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };


  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col gap-4 items-center justify-center"
    >
        {showError}
        <div className="flex flex-row gap-6">
            <div className="flex flex-1 flex-col gap-1">
                <labe className="font-bold text-base  text-center">USERNAME</labe>
                <input
                    className="bg-lightGray rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="email"
                    id="email"

                    onChange={handleChange}
                />
            </div>
        </div>
      <div className="flex flex-row gap-6">
        <div className="flex flex-1 flex-col gap-1">
          <labe className="font-bold text-base text-center">PASSWORD</labe>
          <input
              className="bg-lightGray rounded-lg p-2 text-base font-interMedium"
              type="password"
              name="password"
              id="password"

              onChange={handleChange}
          />
        </div>
      </div>


        <button  onClick={handleLogin}  className="flex justify-center text-center font-bold bg-black items-center border-[2px] border-gray-500 px-4 py-2  rounded-2xl" style={{color:"white",paddingLeft:40, paddingRight:50}}>
        LOG IN
      </button>
      {/*<div
        className="hover:underline hover:cursor-pointer text-[#1877f2]"
        onClick={navigateToReset}
      >
        Forgot Password?
      </div>*/}
    </form>
  );
};

export default LoginForm;
