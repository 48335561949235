import logo from "./logo.svg";
import { Routes, Route } from "react-router-dom";
import "./App.css";

import Assets from "./pages/assets.page";
import Asset from "./pages/asset.page";
import Claims from "./pages/claims.page";
import Claim from "./pages/claim.page";
import Dashboard from "./pages/dashboard.page";
import LoggedIn from "./layouts/loggedIn.layout";
import Login from "./pages/login.page";
import Test from "./pages/test.component";
import CreateClaim from "./pages/createClaim.page";
import SendPasswordReset from "./pages/sendPasswordReset";
import PasswordReset from "./pages/passwordReset.page";
import Support from "./pages/support.page";
import TermsConditions from "./pages/TermsConditions.page";
import ShippingLabels from "./pages/shippingLabels.page";
import AccountInformation from "./pages/accountInformation.page";
import ChangePassword from "./pages/changePassword.page";
import ManageContacts from "./pages/manageContacts.page";
import customers from "./pages/customers.page";
import ContactSave from "./pages/contactSave.page";
import CustomerCreate from "./pages/customerCreate.page";
import ContactEdit from "./pages/contactEdit.page";
import CustomerEdit from "./pages/customerEdit.page";
import Customers from "./pages/customers.page";

function App() {
  document.getElementById("root").classList.add("bg-mainBG");
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Dashboard />} />
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="assets/:assetId" element={<Asset />} />
      <Route path="assets" element={<Assets />} />
      <Route path="claims/:claimId" element={<Claim />} />
      <Route path="claims" element={<Claims />} />
      <Route path="create-claim" element={<CreateClaim />} />
      <Route path="send-password-reset" element={<SendPasswordReset />} />
      <Route path="password-reset/:token" element={<PasswordReset />} />
      <Route path="support" element={<Support />} />
      <Route path="shipping-labels" element={<ShippingLabels />} />
      <Route path="account-info" element={<AccountInformation />} />
      <Route path="change-password" element={<ChangePassword />} />
      <Route path="manage-contacts/:customerId" element={<ManageContacts />} />
      <Route path="customers" element={<Customers />} />
      <Route path="contact-save" element={<ContactSave />} />
      <Route path="customer-create" element={<CustomerCreate />} />
      <Route path="contact-edit/:contactId" element={<ContactEdit />} />
      <Route path="customer-edit/:customerId" element={<CustomerEdit />} />
      <Route path="programterms-consumerelectronics" element={<TermsConditions />} />
    </Routes>
  );
}

export default App;
