import LoggedIn from "../layouts/loggedIn.layout";
import DefaultLayout from "../layouts/default.layout";
import ContactAddForm from "../components/forms/contactAdd.form";
import CardTemplate from "../components/cards/template/card.template.component";
import {useSearchParams} from "react-router-dom";
import {useContext} from "react";
import {UserContext} from "../contexts/user.context";

const Support = () => {
    const [searchParams] = useSearchParams();
    const  customerId  = searchParams.get('customerId');
    const {user} = useContext(UserContext);

    //console.log(`customerId: ${customerId}`, user);

    const heading = (user?.role === "codi admin" && customerId == user?.customer_id) ? "New Codi Admin" : "New Contact";
  return (
      <LoggedIn>
        <DefaultLayout title={heading}>
          <CardTemplate>
            <ContactAddForm customerId={customerId} />
          </CardTemplate>
        </DefaultLayout>
      </LoggedIn>
  );
};

export default Support;
