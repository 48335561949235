import { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { LOGIN_CONTACT_MUTATION } from "../api/mutations/contact.mutations";
import { UserContext } from "../contexts/user.context";

const DEFAULT_LOGIN_FORM = {
  email: "",
  password: "",
};

const useLogin = () => {
  const { user, setUser } = useContext(UserContext);
  const [loginForm, setLoginForm] = useState(DEFAULT_LOGIN_FORM);
  const { email, password } = loginForm;
  const [error, setError] = useState(null);

  const [login, { loading }] = useMutation(LOGIN_CONTACT_MUTATION, {
    onError: (error) => {
      setError(error.message);
      alert(error.message);
    },
    onCompleted: (res) => {
      setUser(res.loginContact.contact);
      localStorage.setItem("token", res.loginContact.token);
    },
  });

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      await login({ variables: { email, password } });
    } catch (error) {
      setError(error.message);
    }
  };

  return {
    loginForm,
    setLoginForm,
    error,
    loading,
    handleLogin,
  };
};

export default useLogin;
