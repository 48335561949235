import Section from "../layouts/section.layout";
import CoveredDevicesCard from "./cards/coveredDevices.card";
import OpenClaimsCard from "./cards/openClaims.card";
import ClosedClaimsCard from "./cards/closedClaims.card";
import TotalClaimsCard from "./cards/totalClaims.card";
import useAsset from "../hooks/useAsset";
import useTicket from "../hooks/useTicket";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/user.context";

const DEFAULT_DASHBOARD_DETAILS = {
  coveredDevices: 0,
  openClaims: 0,
  closedClaims: 0,
  totalClaims: 0,
};

const DashboardDetails = () => {
  const { user } = useContext(UserContext);
  const { getTicketCount } = useTicket();
  const { getAssetCount } = useAsset();
  const [dashboardDetails, setDashboardDetails] = useState(
    DEFAULT_DASHBOARD_DETAILS
  );
  const { coveredDevices, openClaims, closedClaims, totalClaims } =
    dashboardDetails;

  useEffect(() => {
    if (!user?.id) return;
    const fetchAndSetDashboardDetails = async () => {
      const closedCount = await getTicketCount({
        TicketInput: { status: "Resolved" },
      });
      const ticketCount = await getTicketCount({ TicketInput: {} });
      const openCount = await getTicketCount({
        TicketInput: { status: "-Resolved" },
      });
      let AssetInput = {};
      if(user?.role !== "codi admin"){
        console.log("user?.customer_id",user?.customer_id)
        AssetInput.customer_id = user?.customer_id;
      }
      // if(user?.role === "admin"){
      //   AssetInput.customer_id = user?.customer_id;
      // }
      const assetCount = await getAssetCount({ AssetInput });
      setDashboardDetails({
        coveredDevices: assetCount,
        totalClaims: ticketCount,
        openClaims: openCount,
        closedClaims: closedCount,
      });
    };
    fetchAndSetDashboardDetails();
  }, []);

  return (
      window.screen.width <= 678 ?
    <Section>
      <div className="flex flex-col gap-4 justify-evenly">
        <CoveredDevicesCard coveredDevices={coveredDevices} />
        <OpenClaimsCard openClaims={openClaims} />
        <ClosedClaimsCard closedClaims={closedClaims} />
        <TotalClaimsCard totalClaims={openClaims+closedClaims} />
      </div>
    </Section>
           :
          <Section>
            <div className="flex flex-row gap-4 justify-evenly">
              <CoveredDevicesCard coveredDevices={coveredDevices} />
              <OpenClaimsCard openClaims={openClaims} />
              <ClosedClaimsCard closedClaims={closedClaims} />
              <TotalClaimsCard totalClaims={openClaims+closedClaims} />
            </div>
          </Section>
  );
};

export default DashboardDetails;
