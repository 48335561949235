import DefaultLayout from "../layouts/default.layout";
import CustomersTableCard from "../components/cards/CustomersTable.card";
import LoggedIn from "../layouts/loggedIn.layout";

const Customers = () => {
  return (
    <LoggedIn>
      <DefaultLayout title="Customers">
        <CustomersTableCard />
      </DefaultLayout>
    </LoggedIn>
  );
};

export default Customers;
