import LoggedIn from "../layouts/loggedIn.layout";
import DefaultLayout from "../layouts/default.layout";
import CustomerSaveForm from "../components/forms/customerSave.form";
import CardTemplate from "../components/cards/template/card.template.component";
import {useParams, useSearchParams} from "react-router-dom";
import {UserContext} from "../contexts/user.context";
import {useContext} from "react";

const CustomerEdit = () => {
  const { customerId } = useParams();
  const [searchParams] = useSearchParams();

  const {user} = useContext(UserContext);
  const heading =  "Edit Customer";

  return (
      <LoggedIn>
        <DefaultLayout title={heading}>
          <CardTemplate>
            <CustomerSaveForm customerId={customerId} />
          </CardTemplate>
        </DefaultLayout>
      </LoggedIn>
  );
};

export default CustomerEdit;
