import { useLazyQuery } from "@apollo/client";
import {
  FETCH_CONTACTS,
  FETCH_CONTACTS_COUNT_QUERY,
} from "../api/queries/contact.queries";
import { useContext } from "react";
import { UserContext } from "../contexts/user.context";

const useContact = () => {
  const { user } = useContext(UserContext);
  const [contactsQuery] = useLazyQuery(FETCH_CONTACTS, {
    fetchPolicy: "network-only",
  });
  const [contactCountQuery] = useLazyQuery(FETCH_CONTACTS_COUNT_QUERY, {
    fetchPolicy: "network-only",
  });

  const fetchContactCount = async ({ searchParams, customer_id }) => {
    try {
      const response = await contactCountQuery({
        variables: {
          searchParams,
          customer_id,
        },
      });
      if (response?.error) throw new Error(response.error);
      return response?.data?.fetchContactsCount || 0;
    } catch (error) {
      console.log(error?.message);
    }
  };

  const fetchContacts = async ({ searchParams, customer_id }) => {
    try {

      const response = await contactsQuery({
        variables: {
          searchParams,
          customer_id,

        },
      });
      if (response?.error) throw new Error(response.error);
      return response?.data?.fetchContacts || [];
    } catch (error) {
      console.error(error?.message);
    }
  };

  return { fetchContacts, fetchContactCount };
};

export default useContact;
