import { useLazyQuery } from "@apollo/client";
import {
  FETCH_CUSTOMERS_COUNT_QUERY, FETCH_CUSTOMERS_QUERY,
} from "../api/queries/customer.queries";
import { useContext } from "react";
import { UserContext } from "../contexts/user.context";

const useCustomers = () => {
  const { user } = useContext(UserContext);
  const [customersQuery] = useLazyQuery(FETCH_CUSTOMERS_QUERY, {
    fetchPolicy: "network-only",
  });
  const [customerCountQuery] = useLazyQuery(FETCH_CUSTOMERS_COUNT_QUERY, {
    fetchPolicy: "network-only",
  });

  const fetchCustomerCount = async ({ searchParams }) => {
    try {
      const response = await customerCountQuery({
        variables: {
          searchParams
        },
      });
      if (response?.error) throw new Error(response.error);
      return response?.data?.fetchCustomerCount || 0;
    } catch (error) {
      console.log(error?.message);
    }
  };

  const fetchCustomers = async ({ searchParams, CustomerInput }) => {
    try {

      const response = await customersQuery({
        variables: {
          searchParams
        },
      });
      if (response?.error) throw new Error(response.error);
      return response?.data?.fetchCustomers || [];
    } catch (error) {
      console.error(error?.message);
    }
  };

  return { fetchCustomers, fetchCustomerCount };
};

export default useCustomers;
