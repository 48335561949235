const { createContext, useState, useEffect } = require("react");

export const CustomerContext = createContext({
  customer: null,
  setCustomer: () => null,
});

export const CustomerProvider = ({ children }) => {
  const [customer, setCustomer] = useState(null);
  useEffect(() => {
    console.log(customer);
  }, [customer]);
  return (
    <CustomerContext.Provider value={{ customer, setCustomer }}>
      {children}
    </CustomerContext.Provider>
  );
};
