import LoggedIn from "../layouts/loggedIn.layout";
import DefaultLayout from "../layouts/default.layout";
import UpdatePasswordForm from "../components/forms/updatePassword.form";
import CardTemplate from "../components/cards/template/card.template.component";

const Support = () => {
  return (
      <LoggedIn>
        <DefaultLayout title="Change Password">
          <CardTemplate>
            <UpdatePasswordForm />
          </CardTemplate>
        </DefaultLayout>
      </LoggedIn>
  );
};

export default Support;
