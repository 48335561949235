import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton = ({ onClick }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  return (
    <button
      className="border border-codiRed bg-white hover:bg-codiRed hover:text-white text-codiRed font-bold py-2 px-8 rounded-lg"
      onClick={onClick || handleClick}
    >
      Back
    </button>
  );
};

export default BackButton;
