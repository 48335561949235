import LoggedOutLayout from "../layouts/loggedOut.layout";
import emblemImage from "../assets/images/codi-emblem.png";
import { useState } from "react";
import { useLocation  } from "react-router-dom";
import ResetPasswordButton from "../components/buttons/resetPassword.button.component";

const DEFAULT_PASSWORD_FORM = {
  password: "",
  confirmPassword: "",
};

const PasswordReset = () => {
  const [passwordForm, setPasswordForm] = useState(DEFAULT_PASSWORD_FORM);
  const { password, confirmPassword } = passwordForm;
  const handleChange = (e) => {
    setPasswordForm({ ...passwordForm, [e.target.name]: e.target.value });
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const isEnrollment =  queryParams.has('enrollment');

  return (
    <LoggedOutLayout>
      <div className="gap-y-6 flex flex-col bg-white p-8 justify-center items-center">
        <img src={emblemImage} alt="CODi Emblem" style={{ width: 200 }} />
        <h1 className="text-center text-lg font-interExtraBold text-codiDarkGray">
          {isEnrollment ? "Create Password": "Reset Password"}
        </h1>
        <div className="gap-y-2 flex flex-col">
          <label className="text-codiTableHeaders text-center">
            Enter a new and secure password
          </label>
          <input
            className="border border-codiTableHeaders rounded p-2 text-base font-interMedium"
            type="password"
            autoComplete="off"
            name="password"
            value={password}
            onChange={handleChange}
          />
        </div>
        <div className="gap-y-2 flex flex-col">
          <label className="text-codiTableHeaders text-center">
            Confirm Password
          </label>
          <input
            className="border border-codiTableHeaders rounded p-2 text-base font-interMedium"
            type="password"
            autoComplete="off"
            name="confirmPassword"
            value={confirmPassword}
            onChange={handleChange}
          />
        </div>
        <div className="gap-x-12 flex flex-row">
          <ResetPasswordButton
            passwordFormState={[passwordForm, setPasswordForm]}
            isEnrollment ={isEnrollment}
          />
        </div>
      </div>
    </LoggedOutLayout>
  );
};

export default PasswordReset;
