import React, {useContext, useState, useEffect} from "react";
import {userContext} from "../../contexts/user.context"
import {useLazyQuery, useMutation} from "@apollo/client";
import {UPDATE_CONTACT_MUTATION, DELETE_CONTACT} from "../../api/mutations/contact.mutations"
import {UserContext} from "../../contexts/user.context";
import {CONTACT_QUERY} from "../../api/queries/contact.queries";
import {CustomerContext} from "../../contexts/customer.context";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useNavigate } from "react-router-dom";
import {onError} from "@apollo/client/link/error";

const states = ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming']

const CONTACT = {
    email: "", name: "", address1: "", phone:"", city:"", state:"", zip:"", role:""
}
const ContactSaveForm = (contactId ) => {
    const [ContactForm, setContactForm] = useState();
    //console.log(contact);
    //setContactForm(contact);
    const [contact, setContact] = useState({});
    const {customer} = useContext(CustomerContext);
    const navigate = useNavigate();
    const customer_id = customer.id;
    let _id = contactId;

    if(typeof  _id == "object"){
        _id = _id.contactId;
    }

    const [Contact, { error, loading, data }] = useLazyQuery(CONTACT_QUERY,   {
        variables: {
            customer_id,
            _id
        },
        onCompleted: (data) => {
            //console.log(data.getContact);
            setContact(data.getContact);
        },
        fetchPolicy: "network-only",
    });


    useEffect(() => {
    // get asset from API
    const id = contactId;
    Contact({ variables: { _id } });
      }, [contactId]);
    const {email, name, /*password, */address1, phone, city, state, zip, role} = contact;

    const handleChange = (e) => {
        setContact({...contact, [e.target.name]: e.target.value});
    };

    //console.log(ContactForm);

    const user = useContext(UserContext).user;

    const [UpdateContact] = useMutation(UPDATE_CONTACT_MUTATION);
    const [deleteContact] = useMutation(DELETE_CONTACT);

    const handleSubmit = () => {

        try{
            if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)){
                alert("Your email is incorrect!");
                return false;
            }

            /*if(phone !== "" && !/^[0-9\-\+\s]+$/.test(phone)){
                alert("Your phone number is incorrect!");
                return false;
            }*/

            //console.log(`"${phone}"`, (phone !== null));


            if(!(phone === "" || phone === null)){
                if(!/^[0-9\-\+\s]{10,15}$/.test(phone)){
                    alert("Your phone number is incorrect!");
                    return false;
                }
            }

            if(address1 === ""){
                alert("Address is required!");
                return false;
            }

            if(city === ""){
                alert("City is required!");
                return false;
            }

            if(state === ""){
                alert("State is required!");
                return false;
            }

            if(zip === ""){
                alert("Zip is required!");
                return false;
            }

            if(role === ""){
                alert("Role is required!");
                return false;
            }

            UpdateContact({
                variables: {
                    _id: _id,
                    email: email,
                    name: name,
                    //password: password,
                    address1: address1,
                    phone: phone,
                    city: city,
                    state: state,
                    zip: zip,
                    customer_id: user.customer_id,
                    role: role
                }, onCompleted: (res) => {
                    alert(`Contact updated. Contact #${res.updateContact._id}`);
                },
                onError: (e) => {
                    alert(e.message);
                }
        });
        }
        catch (error){
            alert(error?.message);
        }

    }

    //console.log(email, name, address1, city, state, zip, phone, role);

    const handleDelete = () =>{

        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure want to delete this user!',
            buttons: [
                {
                    label: 'Confirm Delete',
                    onClick: () => actionConfirmDelete()
                },
                {
                    label: 'Cancel',
                    //onClick: () => alert('Click No')
                }
            ]
        });

    }

    const actionConfirmDelete = () => {
        try {

            deleteContact({
                variables: {
                    _id: _id
                }, onCompleted: (res) => {
                    alert(`Contact Deleted!`);

                    console.log(res);
                    navigate("/manage-contacts/"+customer_id);

                }, onError: (e) => {
                    alert(e.message) ;
                    //console.log(e);

                }
            })
        }
        catch (e) {
            alert(e.message);
            console.log(e);
        }
    }

    //console.log(contact)

    return (
        <form className=" gap-4 contact-form">

            <div className="flex flex-col">
                <label className="" htmlFor="name">
                    Name *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="name"
                    id="name"
                    onChange={handleChange}
                    value={name}
                    required
                />
            </div>

            <div className="flex flex-col">
                <label className="" htmlFor="email">
                    Email *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="email"
                    id="email"
                    name="email"
                    onChange={handleChange}
                    value={email}
                    required
                />
            </div>
            {/*<div className="flex flex-col">
                <label className="font-interSemiBold" htmlFor="password">
                    Password:
                </label>
                <input
                    className="bg-lightGray rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="password"
                    id="password"
                    onChange={handleChange}
                    required
                />
            </div>*/}
            <div className="flex flex-col">
                <label className="" htmlFor="address">
                    Address *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="address1"
                    id="address1"
                    onChange={handleChange}
                    value={address1}
                    required
                />
            </div>
            {/*<div className="flex flex-col">
                <label className="font-interSemiBold" htmlFor="address2">
                    Address 2:
                </label>
                <input
                    className="bg-lightGray rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="address2"
                    id="address2"
                    value={address2}
                    onChange={handleChange}
                    required
                />
            </div>*/}
            <div>
            <div className="flex flex-col half-width left" >
                <label className="" htmlFor="city">
                    City *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="city"
                    id="city"
                    onChange={handleChange}
                    required
                    value={city}
                />
            </div>
            <div className="flex flex-col half-width">
                <label className="" htmlFor="state">
                    State *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type='text'
                    name="state"
                    id="state"
                    onChange={handleChange}
                    required
                    value={state}

                />
            </div>
            </div><div>
            <div className="flex flex-col half-width left">
                <label className="" htmlFor="zip">
                    ZIP *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="zip"
                    id="zip"
                    value={zip}
                    onChange={handleChange}
                    required
                />
            </div>

            <div className="flex flex-col  half-width">
                <label className="" htmlFor="phone">
                    Phone
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="phone"
                    id="phone"
                    value={phone}
                    onChange={handleChange}
                    required
                />
            </div>

            </div>

            <div className="flex flex-col">
                <label className="" htmlFor="zip">
                    Role *
                </label>
                <select
                    className=" rounded-lg p-2 text-base font-interMedium"
                    name="role"
                    id="role"
                    onChange={handleChange}
                    required
                    value={role}
                >
                    <option value="user">User</option>
                    <option value="admin">Admin</option>
                    <option value="limited">Limited</option>
                </select>

            </div>

            <div className="flex flex-row justify-evenly">

                <button
                        className=" text-center  bg-black items-center   px-12 py-2  rounded-3xl"
                        style={{color: "white", marginRight: '3em'}} type={"button"} onClick={handleSubmit}>Save Contact
                </button>


                <button
                    className=" text-center  bg-red-700 items-center   px-12 py-2  rounded-3xl"
                    style={{color: "white"}} type={"button"} onClick={handleDelete}>Delete Contact
                </button>

            </div>
        </form>
    );
};

export default ContactSaveForm;
