import { useNavigate } from "react-router-dom";

const CustomerEditLink = ({ id = "" }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/customer-edit/${id}`);
  };
  return (
    <button
      onClick={handleClick}
      className="items-center font-interMedium text-sm gap-1 flex flex-row rounded-xtraLarge px-4 py-2 text-codiBlue bg-cyan-100"
    >
      Edit
    </button>
  );
};

export default CustomerEditLink;
