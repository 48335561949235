import Table from "./table.component";
import TableMessage from "./tableMessage.component";
import ViewDetailsButton from "../buttons/viewDetails.button.component";



const AssetsTable = ({ assets = [],user }) => {
  const ASSETS_HEADERS = [
    {
      header: "Serial #",
      dataField: "asset_serial",
      style: "font-interSemiBold text-codiDarkGray text-base",
    },
    {
      header: "Device Make",
      dataField: "make",
      style: "font-interMedium text-codiDarkGray text-base",
    },
    {
      header: "Device Model",
      dataField: "model",
      style: "font-interMedium text-codiDarkGray text-base",
    },
    {
      header: user?.role!=="codi admin"?"Device Detail" :"Customer",
      dataField: "customer_business_name",
      style: "font-interMedium text-codiDarkGray text-base",
    },
    {
      header: "Details",
      dataField: null,
      style: "",
    },
  ];
  if (assets?.length === 0) {
    return <TableMessage>No Assets Found</TableMessage>;
  }
  return (
    <Table headers={ASSETS_HEADERS}>
      {assets &&
        assets.map((asset, index1) => (
          <tr key={`${asset?._id}-${index1}`} className="h-16">
            {ASSETS_HEADERS.map(({ dataField, style }, index2) => {
              if (index2 === ASSETS_HEADERS?.length - 1) {
                return (
                  <td
                    key={`${asset?._id}-${index1}-${index2}`}
                    className="flex items-center justify-center h-16"
                  >
                    <ViewDetailsButton id={asset?.id} />
                  </td>
                );
              }
              return (
                <td
                  key={`${asset?._id}-${index1}-${index2}`}
                  className={`${style} text-center cell-breakWord w-[${
                    100 / ASSETS_HEADERS?.length
                  }]`}
                >
                  {
                    (dataField === "make" && asset?.[dataField] === null) ? asset?.properties?.Make : ''
                  }
                  {
                    (dataField === "model" && asset?.[dataField] === null) ? asset?.properties?.Model : ''
                  }
                  {
                    (dataField === "customer_business_name" && (asset?.[dataField] === null || asset?.[dataField] === undefined)) ? asset?.name : ''
                  }                  
                  {asset?.[dataField]}
                </td>
              );
            })}
          </tr>
        ))}
    </Table>
  );
};

export default AssetsTable;
