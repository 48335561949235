import {useContext, useEffect, useState} from "react";
import SubmitClaimButton from "../buttons/submitClaim.button.component";
import useAsset from "../../hooks/useAsset";
import {FETCH_CUSTOMERS_QUERY} from "../../api/queries/customer.queries";
import {useQuery} from "@apollo/client";
import {UserContext} from "../../contexts/user.context";

const DEFAULT_FORM_FIELDS = {
  asset_serial: "",
  make: "",
  model: "",
  asset_type: "",
  comment: "",
  customer_id: ""
};

const DEFAULT_ASSET_TYPES = [
  { value: "Chromebook", label: "Chromebook" },
  { value: "Desktop", label: "Desktop" },
  { value: "Game Console", label: "Game Console" },
  { value: "iPad", label: "iPad" },
  { value: "Laptop", label: "Laptop" },
  { value: "MacBook", label: "MacBook" },
  { value: "Phone", label: "Phone" },
  { value: "Tablet", label: "Tablet" },
];

const ClaimForm = () => {
  const { getAssets } = useAsset();
  const [formFields, setFormFields] = useState(DEFAULT_FORM_FIELDS);

  const [assetOptions, setAssetOptions] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState({});
  const { asset_serial, make, model, asset_type, comment, customer_id } = formFields;
  const [customers, setCustomers] = useState([]);
  const {data} = useQuery(FETCH_CUSTOMERS_QUERY);

  const {user} = useContext(UserContext);

  /*fetchCustomers({
     onCompleted:(res) => {
         console.log(res.data);
     }
  })*/



  let searchTimer;

  const handleSearchAssets = (e) => {
    const value = e.target.value;
    setFormFields({ ...formFields, asset_serial: value });
    if (value?.length < 3) {
      if (assetOptions?.length > 0) setAssetOptions([]);
      return;
    }
    // Clear the previous timer if it exists
    if (searchTimer) {
      clearTimeout(searchTimer);
    }
    // Set a new timer to delay the search
    searchTimer = setTimeout(async () => {
      console.log(formFields, formFields.customer_id);
      const AssetInput = {warranty: true, customer_id: user?.role === "codi admin" ? parseInt(customer_id) : user?.customer_id}

      const res = await getAssets({
        searchParams: `search=${value}&limit=5`,
        AssetInput
      });
      setAssetOptions(res);
    }, 700); // 0.7-second delay
  };

  const handleSelectedAsset = (e) => {
    const value = e.target.textContent;
    const selectedAsset = assetOptions.find((asset) => {
      return asset.asset_serial === value;
    });
    if (!selectedAsset) return alert("Asset not found");
    setSelectedAsset(selectedAsset);
    setFormFields({
      ...formFields,
      asset_serial: selectedAsset.asset_serial,
      make: selectedAsset?.make || "",
      model: selectedAsset?.model || "",
      asset_type: selectedAsset.asset_type,
    });
    setAssetOptions([]);
  };

  const handleChange = (e) => {
    setFormFields({ ...formFields, [e.target.name]: e.target.value });
  };


  return (
    <form className="flex flex-col gap-4">
      {(user?.role === "codi admin") ?
       <div className="relative flex flex-1 flex-col gap-1">
      <label htmlFor="asset_serial" className="font-inter text-base">
      Customer
      </label>
      <select required="required"
      id="customer_id"
      name="customer_id"
      onChange={handleChange}
      className="bg-lightGray rounded-lg p-2 text-base font-interMedium">
      <option value=''>    -- Select Customer --   </option>
      {
        data?.fetchCustomers.map( (customer) => <option value={customer.id}>{customer.business_name}</option> )
      }
    </select>
</div> : ''
}

      <div className="relative flex flex-1 flex-col gap-1">
        <label htmlFor="asset_serial" className="font-inter text-base">
          Device Serial Number*:
        </label>
        <p>Begin typing to auto-fill device information</p>
        <input
          list="asset_serial_options"
          id="asset_serial"
          autoComplete="off"
          type="text"
          name="asset_serial"
          value={asset_serial}
          onChange={handleSearchAssets}
          className="bg-lightGray rounded-lg p-2 text-base font-interMedium"
        />
        {assetOptions && (
          <ul className="absolute top-[100px] left-0 mt-2 bg-white shadow-md">
            {assetOptions.map((asset) => (
              <li
                key={asset._id}
                value={asset.asset_serial}
                className="p-2 cursor-pointer hover:bg-gray-100"
                onClick={handleSelectedAsset}
              >
                {asset?.asset_serial}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="flex flex-col gap-6">
        <div className="flex flex-1 flex-col gap-1">
          <label className="font-inter text-base">Make:</label>
          <input
            type="text"
            name="make"
            value={make}
            onChange={handleChange}
            className="bg-lightGray rounded-lg p-2 text-base font-interMedium"
          />
        </div>
        <div className="flex flex-1 flex-col gap-1">
          <label className="font-inter text-base">Model:</label>
          <input
            type="text"
            name="model"
            value={model}
            onChange={handleChange}
            className="bg-lightGray rounded-lg p-2 text-base font-interMedium"
          />
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-1">
        <label className="font-inter text-base">Device Type:</label>
        <select
          name="asset_type"
          value={asset_type}
          onChange={handleChange}
          className="bg-lightGray rounded-lg p-2 text-base font-interMedium"
        >
          {DEFAULT_ASSET_TYPES.map((type) => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </select>
      </div>
      <div className="flex flex-1 flex-col gap-1">
        <label className="font-inter text-base">Claim Details*:</label>
        <textarea
          name="comment"
          value={comment}
          onChange={handleChange}
          className="h-60 bg-lightGray rounded-lg p-2 text-base font-interMedium"
        />
      </div>
      <div className="flex justify-center items-center">
        <SubmitClaimButton
          formFieldsState={[formFields, setFormFields]}
          selectedAssetState={[selectedAsset, setSelectedAsset]}
          assetOptionsState={[assetOptions, setAssetOptions]}
        />
      </div>
    </form>
  );
};

export default ClaimForm;
