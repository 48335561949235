import { useMutation } from "@apollo/client";
import { RESET_PASSWORD_MUTATION } from "../../api/mutations/contact.mutations";

const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const SendPasswordResetEmailButton = ({ emailState }) => {
  const [email, setEmail] = emailState;
  const [resetPassword] = useMutation(RESET_PASSWORD_MUTATION);
  const handleClick = async () => {
    try {
      if (!email || !emailPattern?.test(email))
        return alert("Please enter a valid email address.");
      const response = await resetPassword({
        variables: { email },
      });
      if (response?.error) throw new Error(response.error);
      setEmail("");
      alert("Email has been sent with a password reset link! If you do not have the email in your inbox within 5-10 minutes, please check your spam.");
    } catch (error) {
      alert(error?.message);
    }
  };
  return (
    <button
      className="border border-codiRed hover:bg-white bg-codiRed text-white hover:text-codiRed font-bold py-2 px-8 rounded-lg"
      onClick={handleClick}
    >
      Send Email
    </button>
  );
};

export default SendPasswordResetEmailButton;
