import LoggedOutLayout from "../layouts/loggedOut.layout";
import emblemImage from "../assets/images/codi-emblem.png";
import { useState } from "react";
import BackButton from "../components/buttons/back.button.component";
import SendPasswordResetEmailButton from "../components/buttons/sendPasswordResetEmail.button.component";

const SendPasswordReset = () => {
  const [email, setEmail] = useState("");
  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  return (
    <LoggedOutLayout>
      <div className="gap-y-6 flex flex-col bg-white p-8 justify-center items-center">
        <img src={emblemImage} alt="CODi Emblem" style={{ width: 200 }} />
        <h1 className="text-center text-lg font-interExtraBold text-codiDarkGray">
          Reset Your Password
        </h1>
        <div className="text-codiTableHeaders text-center max-w-[300px]">
          Please enter the email address on your account below. We will email
          you a link to reset your password.
        </div>
        <input
          className="border border-codiTableHeaders rounded p-2 text-base font-interMedium  max-w-[300px] forgot-email"
          type="text"
          autoComplete="on"
          name="email"
          value={email}
          onChange={handleChange}
        />
        <div className="gap-x-12 flex flex-row">
          <BackButton />
          <SendPasswordResetEmailButton emailState={[email, setEmail]} />
        </div>
      </div>
    </LoggedOutLayout>
  );
};

export default SendPasswordReset;
