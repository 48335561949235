const TEST_ASSET = {
  _id: "123423",
  asset_serial: "ILIKEPOTATOES",
  asset_type: "Laptop",
  make: "Dell",
  model: "Latitude",
  warranty: true,
  warranty_type: "TCS Extended Manufacturer's Warranty with Accidental Damage",
  warranty_start_date: "2021-01-01",
  warranty_end_date: "2021-05-02",
  asset_tag: "123456",
};

export default TEST_ASSET;
