import CardTemplate from "./template/card.template.component";
import Comment from "../comment.component";
import AddCommentForm from "../forms/addComment.form";
import { useEffect, useState } from "react";

const FeedbackCommentCard = ({ ticket_id, comments = [] }) => {
  const [feedbackAndComments, setFeedbackAndComments] = useState([]);
  const [newComment, setNewComment] = useState({}, () => null);
  useEffect(() => {
    setFeedbackAndComments(comments);
  }, [comments]);
  useEffect(() => {
    if (newComment?.id === undefined) return;
    setFeedbackAndComments([...feedbackAndComments, newComment]);
  }, [newComment]);

  return (
    <CardTemplate title="Feedback/Comments">
      {feedbackAndComments?.length > 0 ? (
        [...feedbackAndComments]
          .sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return dateA - dateB;
          })
          .map((comment) => <Comment key={comment?.id} {...comment} />)
      ) : (
        <p className="text-center">No feedback/comments</p>
      )}
      <AddCommentForm
        ticket_id={ticket_id}
        newCommentState={[newComment, setNewComment]}
      />
    </CardTemplate>
  );
};

export default FeedbackCommentCard;
